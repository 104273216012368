.ler-mais {
  margin: 20px 0;
}

.ler-mais-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ler-mais-btn:hover {
  background-color: #0056b3;
}
