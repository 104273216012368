.construcao-container {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 8%;
}

.titulo {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.highlight {
  color: #00bfff; /* Azul claro */
}

.descricao {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.botao-voltar {
  padding: 10px 20px;
  background-color: #00bfff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.botao-voltar:hover {
  background-color: #007acc; /* Tom mais escuro de azul no hover */
}
