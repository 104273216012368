.google-map-container {
  width: 100%;
  max-width: 1200px; /* Largura máxima do mapa */
  margin: 0 auto; /* Centraliza o mapa */
  padding: 20px; /* Espaçamento ao redor */
}

iframe {
  width: 100%; /* O mapa ocupa 100% da largura do container */
  height: 450px; /* Altura do mapa */
  border: none; /* Remove a borda padrão */
}
