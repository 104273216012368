.equipe-container {
  text-align: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 8%;
}

.titulo {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.highlight {
  color: #00bfff; /* Azul claro */
}

.descricao {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.equipe-container ul {
    list-style: none;
      font-size: 1.2rem;
      line-height: 1.5;
      margin-bottom: 20px;
}

.equipe-container h2 {
text-align: left !important;
color: #00bfff;
white-space: normal
}

