.coordenadores-container {
  text-align: center;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
}

.titulo {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.highlight {
  color: #00bfff; /* Azul claro */
}

.descricao {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}



.coordenador-card {
  position: relative;
  overflow: hidden;
}

.coordenador-image-container {
  position: relative;
  overflow: hidden;
  width: 98%;
  height: 350px; /* Tamanho único para todas as imagens */
}

.coordenador-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Garante que a imagem cubra todo o espaço sem distorção */
  transition: transform 0.3s ease;
}

.coordenador-image-container:hover .coordenador-image {
  transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: opacity 0.3s ease;
}

.coordenador-image-container:hover .overlay {
  opacity: 1;
}

.coordenador-nome {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.coordenador-descricao {
  font-size: 1rem;
  margin-bottom: 10px;
}

.coordenador-linha {
  width: 80%;
  border: 1px solid white;
  margin-bottom: 10px;
}

.coordenador-redes a {
  margin: 0 10px;
  color: white;
  font-size: 1.5rem;
}

.coordenador-redes a:hover {
  color: #007bff;
}

/* Estilo no mobile para sempre mostrar o nome e redes sociais */
@media (max-width: 768px) {
  .overlay {
    opacity: 1; /* Sempre visível em mobile */
  }
}

/* Ajustes de layout do carousel */
.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
