.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro translúcido */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  position: relative; /* Para posicionar o botão de fechar */
}

.popup-banner {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.popup-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00aaff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.popup-button:hover {
  background-color: #0088cc;
}

/* Botão de fechar no canto superior direito */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

.popup-close:hover {
  color: #ff0000;
}
