/* Container do conteúdo sobre a escola */
.about-school-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Título com destaque em azul claro */
.about-school-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Descrição da escola */
.about-school-description {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 40px;
}

/* Galeria de imagens */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas por linha */
  gap: 20px; /* Espaçamento entre as imagens */
}

/* Estilos das imagens da galeria */
.gallery-image {
  width: 100%;
  height: 250px; /* Definindo uma altura fixa */
  object-fit: cover; /* Assegura que a imagem preencha o espaço sem distorções */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave nas imagens */
  transition: transform 0.3s ease;
}

/* Efeito de zoom ao passar o mouse */
.gallery-image:hover {
  transform: scale(1.05);
}