/* Container do banner */
.banner-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Zoom na imagem ativa */
.slide.active .banner-image {
  transform: scale(1.1);
}

/* Conteúdo do banner */
.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 999;
}

/* Título do banner */
.banner-title {
  font-size: 48px;
  color: #add8e6; /* Azul claro */
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Segundo texto abaixo do título */
.banner-subtitle {
  font-size: 28px;
  color: white; /* Branco */
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Efeito de entrada ao visualizar */
.banner-title.animate,
.banner-subtitle.animate,
.banner-button.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Botão de agendamento - estilo padrão */
.banner-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: white; /* Fundo branco */
  color: black; /* Texto preto */
  font-size: 18px;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid white; /* Borda branca */
  opacity: 0;
  transform: translateY(30px); /* O botão vai subir na animação */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.7s ease, opacity 0.7s ease;
}

/* Efeito hover - botão sem fundo, texto e borda brancos */
.banner-button:hover {
  background-color: transparent; /* Sem fundo */
  color: white; /* Texto branco */
  border: 2px solid white; /* Borda branca */
}


.banner-content.scrolled {
  display: none; /* Azul claro */
}

.blue-tone {
  filter: opacity(0.5);
  display: block;
}

.image-container {
    transform: scale(1.05);
}

.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #24242466
}


@media (max-width: 768px) {
    .banner-content {
      transform: translate(-50%, -170%);
      width: 100%;
    }

    .banner-button {
        padding: 5px 10px;
    }

    .banner-content, h1, h2 {
      font-size: 3.rem;
    }
}

@media (max-width: 500px) {
    .banner-content {
      transform: translate(-50%, -190%);
      width: 100%;
    }

    .banner-button {
        padding: 5px 10px;
    }

    .banner-content h1 {
      font-size: large;
    }

    .banner-content h2 {
      font-size: large;
    }
}

@media (max-width: 500px) {
    .banner-content {
      transform: translate(-50%, -250%);
      width: 100%;
    }

    .banner-button {
        padding: 2px 10px;
    }
}

@media (max-width: 350px) {
    .banner-content {
      transform: translate(-50%, -300%);
    }

    .banner-subtitle {
        display: none;
    }
}