/* Container do carrossel */
.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Título do carrossel */
.carousel-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

/* Cartão de curso */
.carousel-card {
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

/* Imagem do curso com efeito de zoom ao passar o mouse */
.carousel-item-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.carousel-item-image:hover {
  transform: scale(1.05); /* Efeito de zoom */
}

/* Título do curso */
.carousel-item-title {
  font-size: 18px;
  margin-top: 10px;
  color: #333;
}
